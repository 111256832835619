@import "../../../../assets//scss/vars.scss";

.questions {
  justify-content: space-between;

  .question-list {
    width: 65%;
    position: relative;

    .questions-container {
      background-color: $light-color;
      border: 1px solid;
      border-radius: 10px;
      margin: $md 0;
      padding: $md;
      font-size: $md;
      font-weight: bold;
      width: 100%;

      div {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        div {
          border-radius: 10px;
          align-items: center;
          justify-content: center;
          margin: 1%;
          padding: 1%;
          min-width: 8%;
          width: 7%;
        }
      }
    }
  }

  .questions-pdf {
    width: 30%;
    border-radius: 3px;
    border: 1px solid #ccc;
    height: 60vh;
  }
}

.question-main-heading {
  font-size: 18px;
  font-weight: bold;
}

.options-container {
  border: 1px solid black;

  .radio-btn-container {
    border-bottom: 1px solid black;
    padding: $sm;
  }
}

.w-75-p {
  width: 65%;
}

.w-35-p {
  width: 35%;
}

.listening-icon {
  width: 100px;
  height: 100px;
  margin: $sm;
  padding: $sm;

  &:hover {
    cursor: pointer;
    border-radius: $xs;
  }
}

.option-btn {
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #ccc;
  }
}