@import "../../assets/scss/style";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;

  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 00, 0, 0.6);
  }

  .modal-body {
    min-width: 300px;
    position: relative;
    border-radius: 10px;
    background: #ffffff;
    z-index: 10000000;
    animation: slideUp 0.3s;
    max-height: 90vh;
    max-width: 80vw;

    .modal-close {
      font-weight: bold;
      display: flex;
      justify-content: flex-end;
      border: 1px solid $text-color;
      border-radius: 30px;

      height: 20px;
      width: 20px;
    }

    .modal-close i {
      cursor: pointer;
      outline: none;
      font-size: 18px;
    }

    .modal-elements {
      flex: 1;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@media only screen and (orientation:portrait) {
  .modal {
    height: 100vw;
    width: 100vh;

    .modal-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 00, 0, 0.6);
    }
  }
}

// @media only screen and (orientation:landscape) {
//   .modal {
//     height: 100vh;
//     max-height: 100vh;
//     .modal-backdrop {
//       position: absolute;
//       top: 0;
//       left: 0;
//       height: 100%;
//       width: 100%;
//       background: rgba(0, 00, 0, 0.6);
//     }
//   }
// }